import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { showError, showSuccess } from "../../../utils/ToastService";
import { Button } from "primereact/button";
import {
  DeleteServiceAction,
  GetServicesAction,
} from "../../../redux/actions/ServiceAction";

const DeleteService = ({
  categoryId,
  rowData,
  basicRows,
  toast,
  deleteModal,
}) => {
  const dispatch = useDispatch();
  const { loading, currentPage } = useSelector((state) => state.services);
  const submitHandler = (e) => {
    e.preventDefault();
    let info = {
      categoryId: categoryId,
      size: basicRows,
      page: currentPage,
    };
    dispatch(DeleteServiceAction(rowData._id))
      .then(({ payload }) => {
        console.log(payload);
        if (payload?.status) {
          showSuccess(payload.message, toast);
          deleteModal(false);
          dispatch(GetServicesAction(info));
        } else {
          showError(payload?.message, toast);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  return (
    <div className="container">
      <form onSubmit={submitHandler}>
        <h5>Do you like to delete this category ?</h5>

        <span className="actions">
          <Button
            size="small"
            className="dark"
            label="Cancel"
            type="button"
            icon="pi pi-times"
            onClick={() => deleteModal(false)}
          />
          <Button
            size="small"
            className="danger"
            label="Delete"
            icon="pi pi-trash"
            type="submit"
            loading={loading}
          />
        </span>
      </form>
    </div>
  );
};

export default DeleteService;
