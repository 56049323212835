import { createAsyncThunk } from "@reduxjs/toolkit";
import { CreateProjectAPI, DeleteProjectAPI, GetProjectsAPI, UpdateProjectAPI } from "../Api";
import axiosInstance, { errorMessage } from "../constant";

export const GetProjectsAction = createAsyncThunk("admin/projects/get", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.get(GetProjectsAPI + info.page + "&size=" + info.size);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const AddProjectAction = createAsyncThunk("admin/project/add", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.post(CreateProjectAPI, info);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const UpdateProjectAction = createAsyncThunk("admin/project/update", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.patch(UpdateProjectAPI + info.id, info.obj);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const DeleteProjectAction = createAsyncThunk("admin/project/delete", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.delete(DeleteProjectAPI + info);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});