import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Sidebar.scss";

const Sidebar = () => {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("");

  useEffect(() => {
    let path = location.pathname.slice(1);
    let currentPath = path.split("/")[0];
    setActiveTab(currentPath);
  }, [location.pathname]);

  const setTab = (place) => {
    localStorage.setItem("tab", place);
    setActiveTab(place);
  };

  return (
    <>
      <div className="sidebar">
        <div className="sidebar-logo">
          <img src={"/logo.png"} alt="Logo" className="logo-image" />
        </div>
        <ul className="sidebar-list">
          <Link to={"about"} onClick={() => setTab("about")}>
            <li
              className={`sidebar-item ${
                activeTab === "about" ? "visited" : ""
              }`}
            >
              <i className="bi bi-exclamation-circle-fill"></i>
              About
            </li>
          </Link>

          <Link to={"contact"} onClick={() => setTab("contact")}>
            <li
              className={`sidebar-item ${
                activeTab === "contact" ? "visited" : ""
              }`}
            >
              <i className="bi bi-people-fill"></i>
              Contact
            </li>
          </Link>

          <Link to={"requests"} onClick={() => setTab("requests")}>
            <li
              className={`sidebar-item ${
                activeTab === "requests" ? "visited" : ""
              }`}
            >
              <i className="bi bi-x-diamond-fill"></i>
              Requests
            </li>
          </Link>

          <Link to={"services"} onClick={() => setTab("services")}>
            <li
              className={`sidebar-item ${
                activeTab === "services" ? "visited" : ""
              }`}
            >
              <i className="bi bi-braces"></i>
              Services
            </li>
          </Link>

          <Link to={"projects"} onClick={() => setTab("projects")}>
            <li
              className={`sidebar-item ${
                activeTab === "projects" ? "visited" : ""
              }`}
            >
              <i className="bi bi-bricks"></i>
              Projects
            </li>
          </Link>
        </ul>
      </div>
    </>
  );
};

export default Sidebar;
