import React from "react";
import "./Navbar.scss";
import { LogOutAction } from "../../redux/actions/AuthAction";
import { useDispatch, useSelector } from "react-redux";
import { showError, showSuccess } from "../../utils/ToastService";
import LoadingFS from "../loading/LoadingFS";
import { username } from "../../redux/constant";

const Navbar = ({ toast }) => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.auth);

  const logoutHandler = () => {
    dispatch(LogOutAction())
      .then(({ payload }) => {
        console.log(payload);
        if (payload?.status) {
          showSuccess(payload.message, toast);
          window.location.href = "/";
        } else {
          showError(payload?.message, toast);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <>
      {loading && <LoadingFS />}
      <nav className="navbar">
        <div className="username">
          <i className="bi bi-person-fill"></i>
          <span>{username}</span>
        </div>
        <ul className="nav-links">
          <h4 onClick={logoutHandler}>Logout</h4>
        </ul>
      </nav>
    </>
  );
};

export default Navbar;
