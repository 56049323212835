import { configureStore } from '@reduxjs/toolkit';
import AuthReducer from './reducers/AuthReducer';
import ServiceReducer from './reducers/ServiceReducer';
import ProjectReducer from './reducers/ProjectReducer';
import ContactReducer from './reducers/ContactReducer';
import AboutUsReducer from './reducers/AboutUsReducer';


const store = configureStore({
    reducer: {
        auth: AuthReducer,
        services: ServiceReducer,
        projects: ProjectReducer,
        contacts: ContactReducer,
        aboutUs: AboutUsReducer,
    }
});
export default store