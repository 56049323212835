import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Sidebar from "./components/sidebar/Sidebar";
import Navbar from "./components/navbar/Navbar";
import AboutUs from "./pages/about-us/AboutUs";
import Services from "./pages/services/Services";
import Login from "./pages/login/Login";
import Projects from "./pages/projects/Projects";
import Contact from "./pages/contact/ContactDT";
import NotFound from "./components/notfound/NotFound";
import RequestsDT from "./pages/requests/RequestsDT";

const AppRoutes = ({ toast }) => {

    const isLoginPage = window.location.pathname === '/';

    return (
        <Router>
            {!isLoginPage && <Sidebar />}
            <div className="container">
                {!isLoginPage && <Navbar toast={toast} />}
                <Routes>
                    <Route path="/" element={<Login toast={toast} />} />
                    <Route path="*" element={<NotFound />} />
                    <Route path="/services" element={<Services toast={toast} />} />
                    <Route path="/projects" element={<Projects toast={toast} />} />
                    <Route path="/about" element={<AboutUs toast={toast} />} />
                    <Route path="/contact" element={<Contact toast={toast} />} />
                    <Route path="/requests" element={<RequestsDT toast={toast} />} />
                </Routes>
            </div>
        </Router>
    );
};

export default AppRoutes;
