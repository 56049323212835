import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Paginator } from "primereact/paginator";
import { useDispatch, useSelector } from "react-redux";
import { dtTranstion, paginatorSize } from "../../redux/constant";
import { GetContactsAction } from "../../redux/actions/ContactActions";
import { textOverlay } from "../../utils/ToastService";
import "../../assets/utils/datatable.scss";
import LoadingFS from "../../components/loading/LoadingFS";

const ContactDT = ({ categoryId }) => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [basicFirst, setBasicFirst] = useState(0);
  const [basicRows, setBasicRows] = useState(paginatorSize);
  const { totalItems, contacts, loading } = useSelector(
    (state) => state.contacts
  );

  const onBasicPageChange = (event) => {
    let currentPage = event.page + 1;
    setCurrentPage(currentPage);
    setBasicFirst(event.first);
    setBasicRows(event.rows);
  };

  useEffect(() => {
    let info = {
      page: currentPage,
      size: paginatorSize,
    };
    dispatch(GetContactsAction(info));
  }, [dispatch, currentPage, categoryId]);

  const header = (
    <div className="header">
      <span className="title">Contacts</span>
    </div>
  );
  return (
    <>
      {loading && <LoadingFS />}
      <div className={`datatable ${dtTranstion}`}>
        <DataTable stripedRows header={header} value={contacts}>
          <Column field="name" header="User Name"></Column>
          <Column field="email" header="Email"></Column>
          <Column
            body={(rowData) => textOverlay(rowData.message)}
            header="Message"
          ></Column>
          <Column field="createdAt" header="Date"></Column>
        </DataTable>
        <Paginator
          first={basicFirst}
          rows={basicRows}
          totalRecords={totalItems}
          onPageChange={onBasicPageChange}
        ></Paginator>
      </div>
    </>
  );
};

export default ContactDT;
