import { createAsyncThunk } from "@reduxjs/toolkit";
import { CreateServiceAPI, DeleteServiceAPI, GetServicesAPI, UpdateServiceAPI } from "../Api";
import axiosInstance, { errorMessage } from "../constant";

export const GetServicesAction = createAsyncThunk("admin/services/get", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.get(GetServicesAPI + info.page + "&size=" + info.size);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const AddServiceAction = createAsyncThunk("admin/service/add", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.post(CreateServiceAPI, info);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const UpdateServiceAction = createAsyncThunk("admin/service/update", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.patch(UpdateServiceAPI + info.id, info.obj);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const DeleteServiceAction = createAsyncThunk("admin/service/delete", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.delete(DeleteServiceAPI + info);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});