import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetAboutUsAction } from "../../redux/actions/AboutUsAction";
import AboutUsCard from "../../components/about-us-card/AboutUsCard";
import "./AboutUs.scss";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import UpdateAboutUs from "./actions/UpdateAboutUs";
import LoadingFS from "../../components/loading/LoadingFS";

const AboutUs = ({ toast }) => {
  const dispatch = useDispatch();
  const { aboutUs, loading } = useSelector((state) => state.aboutUs);
  const [updateModal, setUpdateModal] = useState(false);

  useEffect(() => {
    dispatch(GetAboutUsAction());
  }, [dispatch]);

  const data = [
    {
      title: "Facebook",
      link: aboutUs?.facebook,
      image: "./social-media/facebook.png",
    },
    {
      title: "LinkedIn",
      link: aboutUs?.linkedIn,
      image: "./social-media/linkedin.png",
    },
    {
      title: "Email",
      link: aboutUs?.email,
      image: "./social-media/mail.png",
    },
    {
      title: "Instagram",
      link: aboutUs?.instagram,
      image: "./social-media/instagram.png",
    },
  ];

  return (
    <div className="about-us">
      {loading && <LoadingFS />}
      <div className="header">
        <h2>About Us</h2>
        <Button
          icon="pi pi-pencil"
          severity="info"
          rounded
          aria-label="Filter"
          onClick={() => setUpdateModal(true)}
        />
      </div>

      <div className="main-desc">
        <p className="english">{aboutUs.main_description}</p>
        <p className="arabic">{aboutUs.main_description_ar}</p>
      </div>

      <div className="grid">
        {data.map((item, index) => {
          return (
            <AboutUsCard
              key={index}
              title={item.title}
              link={item.link}
              image={item.image}
            />
          );
        })}
      </div>
      <Dialog
        header="Update Project"
        dismissableMask
        resizable={false}
        appendTo={"self"}
        visible={updateModal}
        onHide={() => setUpdateModal(false)}
      >
        <UpdateAboutUs
          toast={toast}
          updateModal={(e) => setUpdateModal(e)}
          rowData={aboutUs}
        />
      </Dialog>
    </div>
  );
};

export default AboutUs;
