import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance, { errorMessage } from "../constant";
import { GetContactsAPI, GetRequestsAPI, } from "../Api";

export const GetContactsAction = createAsyncThunk("admin/contacts/get", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.get(GetContactsAPI + info.page + "&size=" + info.size);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const GetRequestsAction = createAsyncThunk("admin/requests/get", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.get(GetRequestsAPI + info.page + "&size=" + info.size);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

