import { Tooltip } from "primereact/tooltip";

export const showSuccess = (msg, toast) => {
  toast.current.show({
    severity: "success",
    summary: "Success",
    detail: msg,
    life: 3000,
  });
};
export const showError = (msg, toast) => {
  toast.current.show({
    severity: "error",
    summary: "Error",
    detail: msg,
    life: 3000,
  });
};

export const showInfo = (msg, toast) => {
  toast.current.show({
    severity: 'info',
    summary: 'Information',
    detail: msg,
    life: 3000,
  });
}

export const showWarn = (msg, toast) => {
  toast.current.show({
    severity: "warn",
    summary: "Warning",
    detail: msg,
    life: 3000,
  });
}

export const showSticky = (title, body, toast) => {
  toast.current.show({
    severity: "info",
    summary: title,
    detail: body,
    sticky: true,
  });
}


export const textOverlay = (rowData) => {
  return (
    <>
      <Tooltip target=".long-text" mouseTrack mouseTrackLeft={100} />
      <div
        className={`long-text ${langType(rowData)}`}
        data-pr-tooltip={rowData}
        height="80px"
      >
        {rowData}
      </div>
    </>
  );
};

export const langType = (text) => {
  const arabicPattern = /[\u0600-\u06FF\u0750-\u077F]/;
  const englishPattern = /^[a-zA-Z0-9\s]+[\s]?$/;
  if (arabicPattern.test(text)) {
    return "ar"
  }
  if (englishPattern.test(text)) {
    return "en"
  }
  return
};

