import React from "react";
import "./AboutUsCard.scss";

const AboutUsCard = ({ image, title, link }) => {
  return (
    <div className="about-us-card">
      <div className="card-header">
        <h3 className="card-title">{title}</h3>
      </div>
      <img src={image} alt={title} className="card-image" />
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={link}
        className="card-link"
      >
        {link}
      </a>
    </div>
  );
};

export default AboutUsCard;
