import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showError, showSuccess } from "../../../utils/ToastService";
import { FileUpload } from "primereact/fileupload";
import { InputTextarea } from "primereact/inputtextarea";
import {
  AddProjectAction,
  GetProjectsAction,
} from "../../../redux/actions/ProjectAction";

const AddProject = ({ categoryId, basicRows, createModal, toast }) => {
  const dispatch = useDispatch();
  const { loading, currentPage } = useSelector((state) => state.projects);
  const [projectInfo, setProjectInfo] = useState([]);
  const submitHandler = (e) => {
    e.preventDefault();
    console.log(projectInfo);
    let formData = new FormData();
    formData.append("title_en", projectInfo.titleEn);
    formData.append("description_en", projectInfo.descriptionEn);
    formData.append("title_ar", projectInfo.titleAr);
    formData.append("description_ar", projectInfo.descriptionAr);
    formData.append("image", projectInfo.image);
    formData.append("url", projectInfo.url);

    let info = {
      categoryId: categoryId,
      size: basicRows,
      page: currentPage,
    };

    dispatch(AddProjectAction(formData))
      .then(({ payload }) => {
        console.log(payload);
        if (payload?.data) {
          showSuccess(payload.message, toast);
          createModal(false);
          dispatch(GetProjectsAction(info));
        } else {
          showError(payload?.message, toast);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  return (
    <div className="container">
      <form onSubmit={submitHandler}>
        <div className="input-wrapper">
          <InputText
            required
            unstyled
            className="custom-input"
            type="text"
            placeholder="Project Link (URL)"
            onChange={(e) =>
              setProjectInfo({ ...projectInfo, url: e.target.value })
            }
          />
        </div>

        <div className="input-wrapper file-upload">
          <h4 className="">Project Image</h4>
          <FileUpload
            mode="basic"
            chooseLabel="Choose an image"
            cancelLabel="Cancel"
            accept="image/*"
            onSelect={(event) =>
              setProjectInfo({ ...projectInfo, image: event.files[0] })
            }
          />
        </div>
        <div className="section">
          <h4 className="">English</h4>
          <div className="input-wrapper">
            <InputText
              required
              unstyled
              className="custom-input"
              type="text"
              placeholder="Project Title"
              onChange={(e) =>
                setProjectInfo({ ...projectInfo, titleEn: e.target.value })
              }
            />
          </div>

          <div className="input-wrapper">
            <InputTextarea
              required
              rows={5}
              className="custom-input"
              id="address"
              autoResize
              placeholder="Project Description"
              onChange={(e) =>
                setProjectInfo({
                  ...projectInfo,
                  descriptionEn: e.target.value,
                })
              }
            />
          </div>
        </div>

        <div className="section">
          <h4 className="">Arabic</h4>
          <div className="input-wrapper">
            <InputText
              required
              unstyled
              className="custom-input ar"
              type="text"
              placeholder="عنوان الخدمة"
              onChange={(e) =>
                setProjectInfo({ ...projectInfo, titleAr: e.target.value })
              }
            />
          </div>

          <div className="input-wrapper">
            <InputTextarea
              required
              rows={5}
              className="custom-input ar"
              id="address"
              autoResize
              placeholder="وصف الخدمة"
              onChange={(e) =>
                setProjectInfo({
                  ...projectInfo,
                  descriptionAr: e.target.value,
                })
              }
            />
          </div>
        </div>

        <span className="actions">
          <Button
            size="small"
            className="dark"
            label="Cancel"
            icon="pi pi-times"
            onClick={() => createModal(false)}
            type="submit"
          />
          <Button
            size="small"
            className="info"
            label="Add"
            icon="pi pi-check"
            type="submit"
            loading={loading}
            disabled={!projectInfo.image}
          />
        </span>
      </form>
    </div>
  );
};

export default AddProject;
