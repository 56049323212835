import React, { useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useDispatch, useSelector } from "react-redux";
import { showError, showSuccess } from "../../utils/ToastService";
import { LogInAction } from "../../redux/actions/AuthAction";

const LoginForm = ({ toast }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const handleLogin = (e) => {
    e.preventDefault();
    let data = new FormData();
    data.append("email", email);
    data.append("password", password);
    dispatch(LogInAction(data))
      .then(({ payload }) => {
        console.log(payload);
        if (payload?.data) {
          showSuccess(payload.message, toast);
          window.location.href = "/about";
        } else {
          showError(payload?.message, toast);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div className="login-form animate__animated animate__pulse">
      <div className="logo">
        <img alt="blue ocean" src={"./logo.png"} />
      </div>
      <form onSubmit={handleLogin}>
        <div className="input-wrapper">
          <h5>Email Adderss</h5>
          <InputText
            required
            unstyled
            className="custom-input"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            placeholder="name@example.com"
          ></InputText>
        </div>
        <div className="input-wrapper">
          <h5>Password</h5>
          <InputText
            required
            unstyled
            className="custom-input"
            type="password"
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>

        <Button
          size="small"
          className="info"
          loading={loading}
          disabled={!(password && email)}
          type="submit"
        >
          Log in
        </Button>
      </form>
    </div>
  );
};

export default LoginForm;
