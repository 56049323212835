import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import LoadingFS from "../../components/loading/LoadingFS";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ProjectsDT from "./ProjectsDT";
import DeleteProject from "./actions/DeleteProject";
import AddProject from "./actions/AddProject";
import UpdateProject from "./actions/UpdateProject";

const Projects = ({ toast }) => {
  const [createModal, setCreateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [file, setFile] = useState(false);

  const [basicRows, setBasicRows] = useState("");
  const [rowData, setRowData] = useState(false);
  const { loading } = useSelector((state) => state.projects);
  const { categoryId } = useParams();

  return (
    <div>
      {loading && <LoadingFS />}
      <ProjectsDT
        categoryId={categoryId}
        toast={toast}
        createModal={(visible, rowData, basicRows) => {
          setCreateModal(visible);
          setRowData(rowData);
          setBasicRows(basicRows);
        }}
        deleteModal={(visible, rowData, basicRows) => {
          setDeleteModal(visible);
          setRowData(rowData);
          setBasicRows(basicRows);
        }}
        updateModal={(visible, rowData, basicRows, file) => {
          setUpdateModal(visible);
          setRowData(rowData);
          setBasicRows(basicRows);
          setFile(file);
        }}
      />

      <Dialog
        header="Add Project"
        dismissableMask
        resizable={false}
        appendTo={"self"}
        visible={createModal}
        onHide={() => setCreateModal(false)}
      >
        <AddProject
          categoryId={categoryId}
          basicRows={basicRows}
          createModal={(e) => setCreateModal(e)}
          toast={toast}
        />
      </Dialog>

      <Dialog
        header="Update Project"
        dismissableMask
        resizable={false}
        appendTo={"self"}
        visible={updateModal}
        onHide={() => setUpdateModal(false)}
      >
        <UpdateProject
          file={file}
          categoryId={categoryId}
          rowData={rowData}
          basicRows={basicRows}
          toast={toast}
          updateModal={(e) => setUpdateModal(e)}
        />
      </Dialog>

      <Dialog
        closeOnEscape
        header="Delete Project"
        dismissableMask
        resizable={false}
        appendTo={"self"}
        visible={deleteModal}
        onHide={() => setDeleteModal(false)}
      >
        <DeleteProject
          categoryId={categoryId}
          rowData={rowData}
          basicRows={basicRows}
          toast={toast}
          deleteModal={(e) => setDeleteModal(e)}
        />
      </Dialog>
    </div>
  );
};

export default Projects;
