import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showError, showSuccess } from "../../../utils/ToastService";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import {
  GetProjectsAction,
  UpdateProjectAction,
} from "../../../redux/actions/ProjectAction";
import { InputTextarea } from "primereact/inputtextarea";
import { FileUpload } from "primereact/fileupload";
import { imageURL } from "../../../redux/Api";

const UpdateProject = ({
  categoryId,
  rowData,
  basicRows,
  updateModal,
  toast,
}) => {
  const dispatch = useDispatch();
  const { loading, currentPage } = useSelector((state) => state.projects);
  const [projectInfo, setProjectInfo] = useState([]);
  const submitHandler = (e) => {
    e.preventDefault();
    let formData = new FormData();
    let info = {
      categoryId: categoryId,
      size: basicRows,
      page: currentPage,
    };
    console.log(projectInfo);
    formData.append("url", projectInfo.url ?? rowData.URL);
    formData.append("title_en", projectInfo.titleEn ?? rowData.title_en);
    formData.append(
      "description_en",
      projectInfo.descriptionEn ?? rowData.description_en
    );
    formData.append("title_ar", projectInfo.titleAr ?? rowData.title_ar);
    formData.append(
      "description_ar",
      projectInfo.descriptionAr ?? rowData.description_ar
    );
    if (projectInfo.image !== undefined)
      formData.append("image", projectInfo.image);

    let data = { id: rowData._id, obj: formData };

    dispatch(UpdateProjectAction(data))
      .then(({ payload }) => {
        console.log(payload);
        if (payload?.data) {
          showSuccess(payload.message, toast);
          updateModal(false);
          dispatch(GetProjectsAction(info));
        } else {
          showError(payload?.message, toast);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  return (
    <div className="container">
      <form onSubmit={submitHandler}>
        <div className="input-wrapper">
          <InputText
            defaultValue={rowData.URL}
            required
            unstyled
            className="custom-input"
            type="text"
            placeholder="Project Link (URL)"
            onChange={(e) =>
              setProjectInfo({ ...projectInfo, url: e.target.value })
            }
          />
        </div>
        <div className="section input-wrapper file-upload">
          <div className="image-preview">
            {projectInfo.image ? (
              <img src={projectInfo.image.objectURL} alt="project-preview" />
            ) : (
              <img src={imageURL + rowData.imagePath} alt="project-preview" />
            )}
          </div>
          <h4 className="">Project Image</h4>
          <FileUpload
            mode="basic"
            chooseLabel="Choose an image"
            cancelLabel="Cancel"
            accept="image/*"
            onSelect={(event) =>
              setProjectInfo({ ...projectInfo, image: event.files[0] })
            }
          />
        </div>
        <div className="section">
          <h4 className="">English</h4>
          <div className="input-wrapper">
            <InputText
              defaultValue={rowData.title_en}
              required
              unstyled
              className="custom-input"
              type="text"
              placeholder="Project Title"
              onChange={(e) =>
                setProjectInfo({ ...projectInfo, titleEn: e.target.value })
              }
            />
          </div>

          <div className="input-wrapper">
            <InputTextarea
              defaultValue={rowData.description_en}
              required
              rows={5}
              className="custom-input"
              id="address"
              autoResize
              placeholder="Project Description"
              onChange={(e) =>
                setProjectInfo({
                  ...projectInfo,
                  descriptionEn: e.target.value,
                })
              }
            />
          </div>
        </div>

        <div className="section">
          <h4 className="">Arabic</h4>
          <div className="input-wrapper">
            <InputText
              defaultValue={rowData.title_ar}
              required
              unstyled
              className="custom-input ar"
              type="text"
              placeholder="عنوان الخدمة"
              onChange={(e) =>
                setProjectInfo({ ...projectInfo, titleAr: e.target.value })
              }
            />
          </div>

          <div className="input-wrapper">
            <InputTextarea
              defaultValue={rowData.description_ar}
              required
              rows={5}
              className="custom-input ar"
              id="address"
              autoResize
              placeholder="وصف الخدمة"
              onChange={(e) =>
                setProjectInfo({
                  ...projectInfo,
                  descriptionAr: e.target.value,
                })
              }
            />
          </div>
        </div>

        <span className="actions">
          <Button
            size="small"
            className="dark"
            label="Cancel"
            icon="pi pi-times"
            onClick={() => updateModal(false)}
            type="submit"
          />
          <Button
            size="small"
            className="info"
            label="Add"
            icon="pi pi-check"
            type="submit"
            loading={loading}
          />
        </span>
      </form>
    </div>
  );
};

export default UpdateProject;
