import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../assets/utils/datatable.scss";
import { Paginator } from "primereact/paginator";
import { Button } from "primereact/button";
import { useDispatch, useSelector } from "react-redux";
import { dtTranstion, paginatorSize } from "../../redux/constant";
import { projectsUnmount } from "../../redux/reducers/ProjectReducer";
import { GetProjectsAction } from "../../redux/actions/ProjectAction";
import { Image } from "primereact/image";
import { imageURL } from "../../redux/Api";
import { textOverlay } from "../../utils/ToastService";

const ProjectsDT = ({ categoryId, createModal, deleteModal, updateModal }) => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [basicFirst, setBasicFirst] = useState(0);
  const [basicRows, setBasicRows] = useState(paginatorSize);
  const { totalItems, projects } = useSelector((state) => state.projects);

  const onBasicPageChange = (event) => {
    let currentPage = event.page + 1;
    setCurrentPage(currentPage);
    setBasicFirst(event.first);
    setBasicRows(event.rows);
  };

  useEffect(() => {
    let info = {
      page: currentPage,
      size: paginatorSize,
    };
    console.log(currentPage, paginatorSize);
    dispatch(GetProjectsAction(info));
    return () => {
      dispatch(projectsUnmount());
    };
  }, [dispatch, currentPage, categoryId]);

  const header = (
    <div className="header">
      <span className="title">Projects</span>
      <Button
        size="small"
        icon="pi pi-plus"
        onClick={() => {
          createModal(true, undefined, basicRows);
        }}
        rounded
        text
        raised
        aria-label="Favorite"
      />
    </div>
  );
  const acitonBodyTemplate = (rowData) => {
    return (
      <>
        <Button
          size="small"
          icon="pi pi-trash"
          className="p-button-rounded p-button-text p-button-danger"
          aria-label="Submit"
          onClick={() => {
            deleteModal(true, rowData, basicRows);
          }}
        />
        <Button
          size="small"
          icon="pi pi-pencil"
          className="p-button-rounded p-button-text p-button-primary"
          aria-label="Submit"
          onClick={() => {
            updateModal(true, rowData, basicRows);
          }}
        />
      </>
    );
  };
  const imgHandler = (image) => {
    return (
      <Image
        className="image"
        src={imageURL + image}
        alt="offer_img"
        loading="lazy"
        preview
        downloadable
      />
    );
  };
  return (
    <div className={`datatable ${dtTranstion}`}>
      <DataTable stripedRows header={header} value={projects}>
        <Column field="title_en" header="Title En"></Column>
        <Column
          body={(rowData) => textOverlay(rowData.description_en)}
          header="Description En"
        ></Column>
        <Column field="title_ar" header="Title Ar"></Column>
        <Column
          body={(rowData) => textOverlay(rowData.description_ar)}
          header="Description Ar"
        ></Column>
        <Column
          body={(rowData) => imgHandler(rowData.imagePath)}
          header="Project Image"
        ></Column>
        <Column
          align={"center"}
          body={acitonBodyTemplate}
          header="Actions"
        ></Column>
      </DataTable>
      <Paginator
        first={basicFirst}
        rows={basicRows}
        totalRecords={totalItems}
        onPageChange={onBasicPageChange}
      ></Paginator>
    </div>
  );
};

export default ProjectsDT;
